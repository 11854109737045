<template>
  <div class="container flx justify-center">
    <div class="login-form">
      <div class="form-control inp_wrap" :class="{'has-error':hasError('name')}">
        <label>Ваше Имя и Фамилия<span>*</span></label>
        <input
          type="text"
          v-model="form.name"
          placeholder="Имя и Фамилия"
          required
        >
      </div>
      <div class="form-control inp_wrap" :class="{'has-error':hasError('login')}">
        <label>Логин<span>*</span></label>
        <input
          type="text"
          v-model="form.login"
          placeholder="Ваш логин"
          required
        >
      </div>
      <div class="form-control inp_wrap" :class="{'has-error':hasError('phone')}">
        <label>Номер телефона<span>*</span></label>
        <input
          type="tel"
          v-model="form.phone"
          v-mask="'+38(0##) ###-##-##'"
          required
        >
      </div>
      <div class="form-control inp_wrap" :class="{'has-error':hasError('telegram')}">
        <label>Telegram</label>
        <input
          type="text"
          v-model="form.telegram"
          placeholder="@telegram"
          required
        >
      </div>
      <div class="form-control inp_wrap" :class="{'has-error':hasError('email')}">
        <label>E-mail</label>
        <input
          type="text"
          v-model="form.email"
          placeholder="example@example.com"
          required
          name="email"
        >
      </div>
      <div class="form-control inp_wrap" :class="{'has-error':hasError('password')}">
        <div class="input-group">
          <label>Пароль<span>*</span></label>
          <input :type="view_password ? 'text' : 'password'" name="password" v-model="form.password"
                 placeholder="Ваш пароль" required>
          <span @click="view_password=!view_password" class="show_pass">Показать</span>
        </div>
      </div>
      <button
        :class="{'disabled': isValid}"
        class="btn" @click="sendRegister()"
      >Зарегистрировать
      </button>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'RegisterPage',
  title: 'Регистрация BrewDrop',
  pageClass: 'login-page',
  directives: {
    mask
  },
  data () {
    return {
      view_password: false,
      form: {
        name: '',
        login: '',
        phone: '',
        email: '',
        telegram: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters('errors', ['hasError']),
    ...mapState('auth', ['token', 'user']),
    isValid () {
      return !this.form.name ||
        !this.form.login ||
        !this.form.phone ||
        !this.form.password ||
        this.form.password.length < 6
    }
  },
  methods: {
    ...mapActions('auth', ['register']),
    sendRegister () {
      this.register(this.form).then(response => {
        window.location = '/profile'
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
